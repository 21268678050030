body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Hide the scrollbar */


a{
  color: #000000;
}

/* .ant-card-head {
  background: #FFF6F1;
  color: #000000;
}

.ant-card-body {
  background: #fffaf7;
}

 */

 ::-webkit-scrollbar {
  width: 5px;
  margin-top: 100px;
  background-color: #f5f5f500;
}

/* Change the color of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #f28181;
  border-radius: 5px;

}

/* Change the color of the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f5f5f500;
}
